import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useLogic } from './logic';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export const useAuthRedirect = (route?: string) => {
    // Attributes
    const router = useRouter();
    const currentRoute = route ?? router.asPath;
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

    const { isGlobalAdmin, role } = useSelector(
        (state: RootState) => state.userAndEntity,
    );

    // Effects
    useEffect(() => {
        if (!router.isReady) {
            return;
        }
    }, [router]);

    const { isAuthStartupComplete, isLogged } = useLogic();

    // Effects
    useEffect(() => {
        if (!router.isReady || !isAuthStartupComplete) {
            return;
        }

        if (isLogged) {
            if (!route) {
                return;
            }

            router.push(route);
            return;
        }

        const page = isLogged ? 'select-entity' : 'sign-in';

        router.push(
            `/auth/${page}${
                currentRoute
                    ? `?redirectUrl=${encodeURIComponent(currentRoute)}`
                    : ''
            }`,
        );
    }, [router.isReady, isAuthStartupComplete, isLogged]);

    useEffect(() => {
        if (!router.isReady || !isAuthStartupComplete || !isLogged) {
            return;
        }

        if (currentRoute.startsWith('/settings')) {
            const hasAccess = isGlobalAdmin || role === 'admin';
            setIsAuthorized(hasAccess);

            if (!hasAccess) {
                router.push('/');
            }
        } else {
            setIsAuthorized(true);
        }
    }, [
        router.isReady,
        isAuthStartupComplete,
        isLogged,
        isGlobalAdmin,
        role,
        currentRoute,
    ]);

    return { isAuthorized };
};
