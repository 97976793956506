import { useAuthRedirect } from '../auth/auth-redirect';

const Home = () => {
    // Attributes
    useAuthRedirect('/projects');

    return <></>;
};

export default Home;
